<template>

  <body>
    <div class="container">
      <div class="simbologia">
        <table>
          <thead class="text-white titulo" style="background-color: #6B705C;">
            <tr>
              <th scope="col">Simbología</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <span class="symbol" style="background-color: #FFE8D6;"></span>
                  <span class="ms-2">Calles</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <span class="symbol" style="background-color: #d9534f;"></span>
                  <span class="ms-2">Lotes Vendidos</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <span class="symbol" style="background-color: #9CCE6F;"></span>
                  <span class="ms-2">Lotes Disponibles</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <span class="symbol" style="background-color: #CB997E;"></span>
                  <span class="ms-2">Calles Pavimentadas</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <span class="symbol" style="background-color: #808080;"></span>
                  <span class="ms-2">Vialidad</span>
                </div>
              </td>
            </tr>
            <tr class="description">
              <td>
                <div>
                  <span>Seleccione el lote de su interés</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <InfoModal></InfoModal>
    </div>

    <svg class="plano no-seleccionable" viewBox="0 0 210 297" xmlns="http://www.w3.org/2000/svg" @click="handleClick">
      <sodipodi:namedview id="namedview1" pagecolor="#ffffff" bordercolor="#000000" borderopacity="0.25"
        inkscape:showpageshadow="2" inkscape:pageopacity="0.0" inkscape:pagecheckerboard="0"
        inkscape:deskcolor="#d1d1d1" inkscape:document-units="mm" inkscape:zoom="1" inkscape:cx="522"
        inkscape:cy="522.5" inkscape:window-width="1920" inkscape:window-height="1009" inkscape:window-x="-8"
        inkscape:window-y="-8" inkscape:window-maximized="1" inkscape:current-layer="layer1" />
      <defs id="defs1" />
      <g inkscape:label="Capa 1" inkscape:groupmode="layer" id="layer1">
        <g id="1" lote inkscape:label="1">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 159.2786,62.527418 15.39611,-19.53008 -12.96754,-4.869915 -8.16216,22.429073 z" id="1"
            sodipodi:nodetypes="ccccc" inkscape:label="1" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="159.54375" y="51.72604" id="1"
            inkscape:label="L-1">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="159.54375" y="51.72604" id="1">L-1</tspan>
          </text>
        </g>
        <g id="2" lote inkscape:label="2">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 161.76075,38.181001 -8.19595,22.443066 -5.63803,-2.006756 8.40927,-22.122106 z" id="2"
            sodipodi:nodetypes="ccccc" inkscape:label="2" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="151.87083" y="50.270836" id="text3"
            inkscape:label="L-2">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="151.87083" y="50.270836" id="2">L-2
            </tspan>
          </text>
        </g>
        <g id="3" lote inkscape:label="3">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 156.39576,36.519097 -8.31985,22.078421 -5.76738,-2.134402 8.38948,-21.974667 z" id="3"
            sodipodi:nodetypes="ccccc" inkscape:label="3" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="146.31459" y="48.154167" id="text5"
            inkscape:label="L-3">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="146.31459" y="48.154167" id="3">L-3
            </tspan>
          </text>
        </g>
        <g id="4" lote inkscape:label="4">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 150.78537,34.429076 -8.49663,22.085917 -5.53053,-1.887306 8.37343,-22.110161 z" id="4"
            sodipodi:nodetypes="ccccc" inkscape:label="4" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="141.15521" y="46.566669" id="text6"
            inkscape:label="L-4">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="141.15521" y="46.566669" id="4">L-4
            </tspan>
          </text>
        </g>
        <g id="5" lote inkscape:label="5">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 145.2207,32.414122 -5.88512,-1.964776 -8.17956,22.159288 5.73359,2.006756 z" id="5"
            sodipodi:nodetypes="ccccc" inkscape:label="5" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="135.33438" y="44.317711" id="text7"
            inkscape:label="L-5">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="135.33438" y="44.317711" id="5">L-5
            </tspan>
          </text>
        </g>
        <g id="6" lote inkscape:label="6">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 135.26509,28.993758 -10.60714,21.405407 6.49807,2.102315 8.39287,-22.018348 z" id="6"
            sodipodi:nodetypes="ccccc" inkscape:label="6" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="129.38126" y="42.201046" id="text8"
            inkscape:label="L-6">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="129.38126" y="42.201046" id="6">L-6
            </tspan>
          </text>
        </g>
        <g id="7" lote inkscape:label="7">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 136.02957,25.935843 c 44.43532,9.460425 44.44996,9.451205 44.44996,9.451205 l -5.79974,7.624593 -39.4147,-14.017883 z"
            id="7" sodipodi:nodetypes="ccccc" inkscape:label="7" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="154.1198" y="34.660419" id="text9"
            inkscape:label="L-7">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="154.1198" y="34.660419" id="7">L-7</tspan>
          </text>
        </g>
        <g id="8" lote inkscape:label="8">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 148.75804,77.656851 6.68953,-10.068081 -10.30458,-3.63194 -4.13872,10.828255 z" id="8"
            inkscape:label="8" /><text xml:space="preserve" style="font-size:3.175px;fill:#000000;stroke-width:0.264583"
            x="145.73993" y="71.629211" id="text1-4" inkscape:label="L-1">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="145.73993" y="71.629211" id="8">L-1
            </tspan>
          </text>
        </g>
        <g id="9" lote inkscape:label="9">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 141.00427,74.801976 4.13872,-10.86204 -5.65907,-1.942666 -4.08805,10.811362 z" id="9"
            inkscape:label="9" /><text xml:space="preserve" style="font-size:3.175px;fill:#000000;stroke-width:0.264583"
            x="137.67014" y="70.174004" id="text3-4" inkscape:label="L-2">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="137.67014" y="70.174004" id="9">L-2
            </tspan>
          </text>
        </g>
        <g id="10" lote inkscape:label="10">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 135.37898,72.808632 4.12183,-10.828256 -5.72664,-1.993344 -4.08805,10.946503 z" id="10"
            inkscape:label="10" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="131.98161" y="67.925041" id="text5-7"
            inkscape:label="L-3">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="131.98161" y="67.925041" id="10">L-3
            </tspan>
          </text>
        </g>
        <g id="11" lote inkscape:label="11">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 129.66923,70.967322 4.10494,-10.997184 -5.64218,-1.959559 -4.12183,10.912721 z" id="11"
            inkscape:label="11" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="126.42534" y="65.940674" id="text6-2"
            inkscape:label="L-4">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="126.42534" y="65.940674" id="11">L-4
            </tspan>
          </text>
        </g>
        <g id="12" lote inkscape:label="12">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 121.93449,55.750516 c -5.30357,10.559362 -5.30357,10.559362 -5.30357,10.559362 l 7.40589,2.627894 4.15685,-11.037162 z"
            id="12" inkscape:label="12" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="120.20763" y="63.691711" id="text7-9"
            inkscape:label="L-5">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="120.20763" y="63.691711" id="12">L-5
            </tspan>
          </text>
        </g>
        <g id="13" lote inkscape:label="13">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 111.89805,75.900005 5.69286,2.685949 4.98336,-10.152546 -5.99692,-2.162273 z" id="13"
            inkscape:label="13" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="114.78368" y="73.084427" id="text8-1"
            inkscape:label="L-6">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="114.78368" y="73.084427" id="13">L-6
            </tspan>
          </text>
        </g>
        <g id="14" lote inkscape:label="14">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 122.92902,81.069188 5.355,-10.625542 -5.74353,-2.044023 -4.93269,10.186331 z" id="14"
            inkscape:label="14" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="120.33994" y="75.465683" id="text9-6"
            inkscape:label="L-7">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="120.33994" y="75.465683" id="14">L-7
            </tspan>
          </text>
        </g>
        <g id="15" lote inkscape:label="15">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 128.41917,83.679117 5.5915,-11.343484 -5.76043,-1.875094 -5.32122,10.574864 z" id="15"
            inkscape:label="15" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="125.6316" y="78.243797" id="text9-6-4"
            inkscape:label="L-8">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="125.6316" y="78.243797" id="15">L-8
            </tspan>
          </text>
        </g>
        <g id="16" lote inkscape:label="16">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 133.6897,86.170799 6.0814,-11.824928 -5.79422,-1.993344 -5.55771,11.335038 z" id="16"
            inkscape:label="16" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="131.32014" y="80.360474" id="text9-6-4-7"
            inkscape:label="L-9">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="131.32014" y="80.360474" id="16">L-9
            </tspan>
          </text>
        </g>
        <g id="17" lote inkscape:label="17">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 141.08873,89.650707 7.67419,-12.005042 -8.99457,-3.296817 -6.06805,11.765806 z" id="17"
            inkscape:label="17" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="137.93472" y="83.138596" id="text9-6-4-7-7"
            inkscape:label="L-10">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="137.93472" y="83.138596" id="17">
              L-10</tspan>
          </text>
        </g>
        <g id="18" lote inkscape:label="18">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 129.96063,104.98088 7.79601,-10.051187 -6.73916,-3.198009 -5.66075,10.944686 z" id="18"
            inkscape:label="18" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="128.67432" y="99.542763" id="text1-4-0"
            inkscape:label="L-1">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="128.67432" y="99.542763" id="18">L-1
            </tspan>
          </text>
        </g>
        <g id="29" lote inkscape:label="29">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 94.737025,134.0958 10.897915,-20.76684 7.43677,3.55468 -11.20193,20.64992 z" id="29"
            inkscape:label="29" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="101.8191" y="125.47968" id="text1-4-0-4"
            inkscape:label="L-2">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="101.8191" y="125.47968" id="29">L-2
            </tspan>
          </text>
        </g>
        <g id="19" lote inkscape:label="19">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 125.35736,102.6708 5.65062,-10.954954 -5.49859,-2.550805 -5.58728,11.043639 z" id="19"
            inkscape:label="19" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="122.85348" y="97.293808" id="text3-4-2"
            inkscape:label="L-2">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="122.85348" y="97.293808" id="19">L-2
            </tspan>
          </text>
        </g>
        <g id="28" lote inkscape:label="28">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 108.83283,140.78962 -6.94532,-3.29903 11.17451,-20.56722 7.16029,3.41065 z" id="28"
            inkscape:label="28" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="109.35973" y="129.62103" id="text3-4-2-1"
            inkscape:label="L-1">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="109.35973" y="129.62103" id="28">L-1
            </tspan>
          </text>
        </g>
        <g id="20" lote inkscape:label="20">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 119.92774,100.20376 5.59622,-11.037163 -5.33941,-2.478583 -5.63803,10.91174 z" id="20"
            inkscape:label="20" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="117.29725" y="95.044846" id="text5-7-1"
            inkscape:label="L-3">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="117.29725" y="95.044846" id="20">L-3
            </tspan>
          </text>
        </g>
        <g id="30" lote inkscape:label="30">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 78.14116,139.16077 5.506641,-10.35182 25.168489,11.92279 -5.92832,9.99629 z" id="30"
            inkscape:label="30" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="92.029518" y="140.73354" id="text5-7-1-6"
            inkscape:label="L-3">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="92.029518" y="140.73354" id="30">L-3
            </tspan>
          </text>
        </g>
        <g id="21" lote inkscape:label="21">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 114.55249,97.599754 5.65595,-10.917711 -5.5365,-2.580116 -5.5141,11.007299 z" id="21"
            inkscape:label="21" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="111.87327" y="92.399017" id="text6-2-4"
            inkscape:label="L-4">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="111.87327" y="92.399017" id="21">L-4
            </tspan>
          </text>
        </g>
        <g id="31" lote inkscape:label="31">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 75.396733,144.36229 2.736172,-5.22679 11.931042,5.59806 -2.761589,5.25859 z" id="31"
            inkscape:label="31" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="80.784729" y="145.31567" id="text6-2-4-7"
            inkscape:label="L-4">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="80.784729" y="145.31567" id="31">L-4
            </tspan>
          </text>
        </g>
        <g id="22" lote inkscape:label="22">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 103.19829,92.345102 5.96314,-10.786025 5.50704,2.567699 -5.52394,10.980291 z" id="22"
            inkscape:label="22" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="105.78785" y="90.150055" id="text7-9-6"
            inkscape:label="L-5">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="105.78785" y="90.150055" id="22">L-5
            </tspan>
          </text>
        </g>
        <g id="23" lote inkscape:label="23">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 97.463197,102.6159 5.743533,-10.279246 5.10162,2.38188 -5.28939,10.559266 z" id="23"
            sodipodi:nodetypes="ccccc" inkscape:label="23" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="100.09931" y="100.20423" id="text8-1-1"
            inkscape:label="L-6">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="100.09931" y="100.20423" id="23">L-6
            </tspan>
          </text>
        </g>
        <g id="35" lote inkscape:label="35">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 88.288312,174.99221 6.109615,-10.17294 -7.996806,-2.82165 -3.993555,10.85619 z" id="35"
            inkscape:label="35" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="85.811821" y="169.52507" id="text1-4-0-1"
            inkscape:label="L-1">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="85.811821" y="169.52507" id="35">L-1
            </tspan>
          </text>
        </g>
        <g id="36" lote inkscape:label="36">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 63.787514,166.46247 18.603516,6.39961 4.032242,-10.85499 -17.212158,-6.01022 z" id="36"
            inkscape:label="36" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="72.847229" y="165.15944" id="text3-4-2-8"
            inkscape:label="L-2">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="72.847229" y="165.15944" id="36">L-2
            </tspan>
          </text>
        </g>
        <g id="37" lote inkscape:label="37">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 58.371665,176.94495 5.828981,1.95859 4.055846,-10.88251 -4.468978,-1.55029 z" id="37"
            inkscape:label="37" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="61.205585" y="173.6261" id="text5-7-1-7"
            inkscape:label="L-3">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="61.205585" y="173.6261" id="37">L-3
            </tspan>
          </text>
        </g>
        <g id="38" lote inkscape:label="38">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 64.200646,178.88015 5.793902,1.97028 3.946402,-10.90961 -5.682819,-1.94689 z" id="38"
            inkscape:label="38" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="66.76181" y="175.3459" id="text6-2-4-4"
            inkscape:label="L-4">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="66.76181" y="175.3459" id="38">L-4
            </tspan>
          </text>
        </g>
        <g id="39" lote inkscape:label="39">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 75.525357,182.79147 4.168569,-10.88037 -5.764669,-1.95859 -3.928862,10.89207 z" id="39"
            inkscape:label="39" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="72.582642" y="177.19798" id="text7-9-6-3"
            inkscape:label="L-5">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="72.582642" y="177.19798" id="39">L-5
            </tspan>
          </text>
        </g>
        <g id="40" lote inkscape:label="40">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="M 82.219623,185.11839 88.300004,174.98052 79.653,171.9111 75.515998,182.79222 Z" id="40"
            inkscape:label="40" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="79.726395" y="179.05008" id="text8-1-1-0"
            inkscape:label="L-6">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="79.726395" y="179.05008" id="40">L-6
            </tspan>
          </text>
        </g>
        <g id="24" lote inkscape:label="24">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 108.26938,107.93123 5.41086,-10.754802 -5.38879,-2.466342 -5.27898,10.515734 z" id="24"
            sodipodi:nodetypes="ccccc" inkscape:label="24" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="105.78786" y="102.32091" id="text9-6-9"
            inkscape:label="L-7">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="105.78786" y="102.32091" id="24">L-7
            </tspan>
          </text>
        </g>
        <g id="41" lote inkscape:label="41">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 69.509129,198.98141 3.73724,1.28158 5.87871,-9.8392 -5.771223,-1.93476 z" id="41"
            inkscape:label="41" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="71.788902" y="195.05734" id="text1-4-0-0"
            inkscape:label="L-1">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="71.788902" y="195.05734" id="41">L-1
            </tspan>
          </text>
        </g>
        <g id="42" lote inkscape:label="42">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 69.538519,198.98049 3.823625,-10.50035 -5.904986,-1.98782 -3.73008,10.48866 z" id="42"
            inkscape:label="42" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="65.968063" y="193.99901" id="text3-4-2-0"
            inkscape:label="L-2">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="65.968063" y="193.99901" id="42">L-2
            </tspan>
          </text>
        </g>
        <g id="43" lote inkscape:label="43">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 63.737905,196.97223 3.73724,-10.48411 -5.804297,-1.93477 -3.919141,10.35183 z" id="43"
            inkscape:label="43" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="60.147251" y="191.88234" id="text5-7-1-8"
            inkscape:label="L-3">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="60.147251" y="191.88234" id="43">L-3
            </tspan>
          </text>
        </g>
        <g id="44" lote inkscape:label="44">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 50.591421,192.32549 5.027083,-9.85573 6.085417,2.08359 -3.952214,10.31875 z" id="44"
            inkscape:label="44" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="53.92952" y="189.76567" id="text6-2-4-3"
            inkscape:label="L-4">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="53.92952" y="189.76567" id="44">L-4
            </tspan>
          </text>
        </g>
        <g id="45" lote inkscape:label="45">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 45.228686,202.92104 7.354923,2.56078 4.139337,-10.99146 -6.15054,-2.17491 z" id="45"
            inkscape:label="45" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="48.505562" y="199.42296" id="text7-9-6-5"
            inkscape:label="L-5">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="48.505562" y="199.42296" id="45">L-5
            </tspan>
          </text>
        </g>
        <g id="46" lote inkscape:label="46">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 52.595302,205.44674 7.354923,2.53739 4.010713,-10.94469 -7.261378,-2.56077 z" id="46"
            inkscape:label="46" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="55.781605" y="201.80423" id="46"
            inkscape:label="L-6">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="55.781605" y="201.80423" id="46">L-6
            </tspan>
          </text>
        </g>
        <g id="47" lote inkscape:label="47">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 59.926839,207.96075 7.2263,2.58416 6.103768,-10.28988 -9.319355,-3.20389 z" id="47"
            inkscape:label="47" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="64.248276" y="204.58235" id="text9-6-9-0"
            inkscape:label="L-7">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="64.248276" y="204.58235" id="47">
              L-7</tspan>
          </text>
        </g>
        <g id="32" lote inkscape:label="32">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 84.706134,154.92002 -12.716536,-4.26641 3.40651,-6.30039 11.922786,5.65547 z" id="32"
            inkscape:label="32" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="78.271194" y="151.00423" id="text7-9-6-9"
            inkscape:label="L-5">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="78.271194" y="151.00423" id="32">L-5
            </tspan>
          </text>
        </g>
        <g id="33" lote inkscape:label="33">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 84.722671,154.95309 5.324739,-10.2526 6.598047,3.09232 -5.423958,9.42578 z" id="33"
            inkscape:label="33" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="88.193069" y="152.45943" id="text8-1-1-5"
            inkscape:label="L-6">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="88.193069" y="152.45943" id="33">L-6
            </tspan>
          </text>
        </g>
        <g id="34" lote inkscape:label="34">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 91.204962,157.20205 6.482292,2.21589 5.208986,-8.73125 -6.283856,-2.89388 z" id="34"
            inkscape:label="34" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="94.543076" y="154.44383" id="text9-6-9-6"
            inkscape:label="L-7">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="94.543076" y="154.44383" id="34">L-7
            </tspan>
          </text>
        </g>
        <g id="25" lote inkscape:label="25">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 113.7542,110.51918 5.37322,-10.715577 -5.48274,-2.627897 -5.36028,10.687484 z" id="25"
            sodipodi:nodetypes="ccccc" inkscape:label="25" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="110.94723" y="105.23131" id="text9-6-4-9"
            inkscape:label="L-8">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="110.94723" y="105.23131" id="25">L-8
            </tspan>
          </text>
        </g>
        <g id="26" lote inkscape:label="26">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 119.13987,113.04618 5.4911,-10.71746 -5.50355,-2.531091 -5.42295,10.740601 z" id="26"
            sodipodi:nodetypes="ccccc" inkscape:label="26" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="116.63578" y="107.61257" id="text9-6-4-7-5"
            inkscape:label="L-9">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="116.63578" y="107.61257" id="26">L-9
            </tspan>
          </text>
        </g>
        <g id="27" lote inkscape:label="27">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 123.26636,114.83039 2.33524,-4.04935 1.32052,-1.68496 3.09866,-4.03995 -5.43142,-2.73032 -5.48404,10.75175 z"
            id="27" sodipodi:nodetypes="ccccccc" inkscape:label="27" /><text xml:space="preserve"
            style="font-size:2.34853px;fill:#000000;stroke-width:0.195711" x="119.40591" y="111.46808"
            id="text9-6-4-7-7-3" inkscape:label="L-10" transform="scale(1.0206457,0.97977194)">
            <tspan sodipodi:role="line" style="stroke-width:0.195711" x="119.40591" y="111.46808" id="27">
              L-10</tspan>
          </text>
        </g>
        <g id="48" lote inkscape:label="48">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 54.353465,224.21605 3.894336,1.30638 5.680273,-9.74824 -5.655468,-1.91823 z" id="48"
            sodipodi:nodetypes="ccccc" inkscape:label="48" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="56.575363" y="220.98651" id="text1-4-0-9"
            inkscape:label="L-1">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="56.575363" y="220.98651" id="48">L-1
            </tspan>
          </text>
        </g>
        <g id="49" lote inkscape:label="49">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 48.847682,222.13271 5.654611,1.96758 3.795117,-10.23607 -5.713346,-1.9513 z" id="49"
            inkscape:label="49" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="51.151398" y="219.13443" id="text3-4-2-6"
            inkscape:label="L-2">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="51.151398" y="219.13443" id="49">L-2
            </tspan>
          </text>
        </g>
        <g id="50" lote inkscape:label="50">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 48.846825,222.14899 3.762044,-10.2278 -5.638932,-1.9265 -3.886068,10.21127 z" id="50"
            inkscape:label="50" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="45.462879" y="216.88545" id="50"
            inkscape:label="L-3">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="45.462879" y="216.88545" id="50">L-3
            </tspan>
          </text>
        </g>
        <g id="51" lote inkscape:label="51">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 37.006721,218.12236 5.324739,-9.75651 4.650879,1.61231 -3.886068,10.22366 z" id="51"
            inkscape:label="51" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="39.906605" y="215.16568" id="text6-2-4-1"
            inkscape:label="L-4">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="39.906605" y="215.16568" id="51">L-4
            </tspan>
          </text>
        </g>
        <g id="57" inkscape:label="57">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 39.276928,252.00843 c -3.104311,7.37021 2.580265,0.6782 3.870397,1.0173 l 6.361014,-11.70474 -5.659432,-1.97612 z"
            id="57" sodipodi:nodetypes="ccccc" inkscape:label="57" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="41.758698" y="247.18024" id="text1-4-0-9-5"
            inkscape:label="L-1">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="41.758698" y="247.18024" id="57">
              L-1</tspan>
          </text>
        </g>
        <g id="58" inkscape:label="58">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 39.280483,252.18747 4.615196,-12.8543 -5.706204,-1.95273 -4.867974,13.2761 z" id="58"
            sodipodi:nodetypes="ccccc" inkscape:label="58" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="36.070148" y="246.12192" id="text3-4-2-6-9"
            inkscape:label="L-2">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="36.070148" y="246.12192" id="58">
              L-2</tspan>
          </text>
        </g>
        <g id="59" inkscape:label="59">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 27.398975,249.20276 5.961446,1.5413 4.881673,-13.36947 -5.64774,-1.95274 z" id="59"
            sodipodi:nodetypes="ccccc" inkscape:label="59" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="30.381632" y="244.66669" id="text5-7-1-9-6"
            inkscape:label="L-3">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="30.381632" y="244.66669" id="59">
              L-3</tspan>
          </text>
        </g>
        <g id="60" inkscape:label="60">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 28.548563,234.007 -6.735193,13.66916 5.631375,1.64921 5.167149,-13.90352 z" id="60"
            sodipodi:nodetypes="ccccc" inkscape:label="60" /><text xml:space="preserve"
            style="font-size:3.175px;fill:#000000;stroke-width:0.264583" x="24.825357" y="243.2115" id="text6-2-4-1-2"
            inkscape:label="L-4">
            <tspan sodipodi:role="line" style="stroke-width:0.264583" x="24.825357" y="243.2115" id="60">L-4
            </tspan>
          </text>
        </g>
        <g id="52" lote inkscape:label="52">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 31.274001,228.40804 5.724451,-10.31875 0.56224,0.21498 -3.075781,11.37708 z" id="52"
            sodipodi:nodetypes="ccccc" inkscape:label="52" /><text xml:space="preserve"
            style="font-size:2.24165px;fill:#000000;stroke-width:0.186804" x="35.08831" y="207.59969" id="text7-9-6-1"
            inkscape:label="L-5" transform="scale(0.91048986,1.0983099)">
            <tspan sodipodi:role="line" style="stroke-width:0.186804" x="35.08831" y="207.59969" id="52">L-5
            </tspan>
          </text>
        </g>
        <g id="53" lote inkscape:label="53">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 38.883609,231.16963 3.158464,-11.31094 -4.547527,-1.5875 -2.976562,11.44323 z" id="53"
            sodipodi:nodetypes="ccccc" inkscape:label="53" /><text xml:space="preserve"
            style="font-size:2.90544px;fill:#000000;stroke-width:0.242119" x="34.831467" y="232.32205" id="text8-1-1-05"
            inkscape:label="L-6" transform="scale(1.0284897,0.97229949)">
            <tspan sodipodi:role="line" style="stroke-width:0.242119" x="34.831467" y="232.32205" id="53">L-6
            </tspan>
          </text>
        </g>
        <g id="54" lote inkscape:label="54">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 43.431135,232.72405 -4.564063,-1.55442 3.141927,-11.31094 4.415235,1.47175 z" id="54"
            sodipodi:nodetypes="ccccc" inkscape:label="54" /><text xml:space="preserve"
            style="font-size:2.80508px;fill:#000000;stroke-width:0.233757" x="40.426517" y="226.85112" id="text9-6-9-3"
            inkscape:label="L-7" transform="scale(0.99786478,1.0021398)">
            <tspan sodipodi:role="line" style="stroke-width:0.233757" x="40.426517" y="226.85112" id="54">L-7
            </tspan>
          </text>
        </g>
        <g id="55" lote inkscape:label="55">
          <path
            style="fill:#9CCE6F;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 47.978661,234.29502 -4.597136,-1.60404 3.059245,-11.36054 4.530989,1.57096 z" id="55"
            inkscape:label="55" /><text xml:space="preserve"
            style="font-size:2.71763px;fill:#000000;stroke-width:0.226468" x="46.93647" y="219.94734" id="text9-6-4-9-0"
            inkscape:label="L-8" transform="scale(0.96131882,1.0402376)">
            <tspan sodipodi:role="line" style="stroke-width:0.226468" x="46.93647" y="219.94734" id="55">
              L-8</tspan>
          </text>
        </g>
        <g id="56" lote inkscape:label="56">
          <path
            style="fill:#d9534f;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 47.978661,234.29502 4.464843,1.55443 5.78776,-10.35183 -7.292578,-2.62929 z" id="56"
            inkscape:label="56" /><text xml:space="preserve"
            style="font-size:2.63165px;fill:#000000;stroke-width:0.219304" x="47.517467" y="244.27631"
            id="text9-6-4-7-5-2" inkscape:label="L-9" transform="scale(1.0633298,0.940442)">
            <tspan sodipodi:role="line" style="stroke-width:0.219304" x="47.517467" y="244.27631" id="56">L-9
            </tspan>
          </text>
        </g>
        <g id="g68">
          <path
            style="fill:#CB997E;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 124.65795,50.160264 c -2.81902,5.63803 -2.81902,5.63803 -2.81902,5.63803 l 33.63707,11.801642 3.77461,-5.064673 z"
            id="path8" inkscape:label="path8" /><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11"
            transform="matrix(0.79267108,0.2767137,-0.25296357,0.72463673,44.811379,-19.984056)"
            inkscape:label="Vialidad">
            <tspan id="tspan11" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 2
            </tspan>
          </text><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-3"
            transform="matrix(0.62107546,0.21681135,-0.1982026,0.56776903,63.603517,-1.1904047)"
            inkscape:label="Vialidad">
            <tspan id="tspan11-2" style="stroke-width:0.264583" x="131.36562" y="55.165623">VIALIDAD</tspan>
          </text>
        </g>
        <g id="g67">
          <path
            style="fill:#FFE8D6;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="M 137.70784,94.864354 109.13609,81.546408 111.88961,75.874666 141.0823,89.65634 Z" id="path20"
            sodipodi:nodetypes="ccccc" /><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-3-1"
            transform="matrix(0.62107546,0.21681135,-0.1982026,0.56776903,48.388039,25.490718)"
            inkscape:label="Vialidad">
            <tspan id="tspan11-2-1" style="stroke-width:0.264583" x="131.36562" y="55.165623">VIALIDAD</tspan>
          </text><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-5"
            transform="matrix(0.78030775,0.30986726,-0.28327159,0.71333454,33.47925,2.8227825)"
            inkscape:label="Vialidad">
            <tspan id="tspan11-6" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 4
            </tspan>
          </text>
        </g>
        <g id="g66">
          <path
            style="fill:#CB997E;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 94.355157,107.99782 3.132241,-5.42396 25.814832,12.27832 -3.08405,5.48597 z" id="path31"
            inkscape:label="path31" sodipodi:nodetypes="ccccc" /><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-3-1-3"
            transform="matrix(0.59535477,0.27981169,-0.25579567,0.54425593,40.356775,44.218248)"
            inkscape:label="Vialidad">
            <tspan id="tspan11-2-1-2" style="stroke-width:0.264583" x="131.36562" y="55.165623">VIALIDAD</tspan>
          </text><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-5-4"
            transform="matrix(0.76335287,0.34955728,-0.31955505,0.69783489,22.601656,24.763555)"
            inkscape:label="Vialidad" inkscape:transform-center-x="-8.8157151" inkscape:transform-center-y="-6.572429">
            <tspan id="tspan11-6-8" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 6
            </tspan>
          </text>
        </g>
        <g id="g65">
          <path
            style="fill:#CB997E;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 69.222807,155.98517 2.818023,-5.32033 25.654533,8.72885 -3.30913,5.40219 z" id="path39" /><text
            xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-3-1-3-9"
            transform="matrix(0.6183731,0.22440274,-0.20514243,0.56529862,8.6463422,97.174873)"
            inkscape:label="Vialidad">
            <tspan id="tspan11-2-1-2-4" style="stroke-width:0.264583" x="131.36562" y="55.165623">VIALIDAD</tspan>
          </text><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-5-4-3"
            transform="matrix(0.79243491,0.27738927,-0.25358116,0.72442084,-10.816429,79.126643)"
            inkscape:label="Vialidad" inkscape:transform-center-x="-8.1700521" inkscape:transform-center-y="-7.3507312">
            <tspan id="tspan11-6-8-3" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 8
            </tspan>
          </text>
        </g>
        <g id="g64">
          <path
            style="fill:#FFE8D6;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 79.100275,190.42379 3.141927,-5.34127 -23.882231,-8.15511 -2.712785,5.44896 -0.06431,0.12862 z"
            id="path46" /><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-3-1-3-9-8"
            transform="matrix(0.6183731,0.22440274,-0.20514243,0.56529862,-5.9472366,122.57826)"
            inkscape:label="Vialidad">
            <tspan id="tspan11-2-1-2-4-5" style="stroke-width:0.264583" x="131.36562" y="55.165623">VIALIDAD</tspan>
          </text><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-5-4-3-7"
            transform="matrix(0.79243491,0.27738927,-0.25358116,0.72442084,-25.810568,104.83578)"
            inkscape:label="Vialidad" inkscape:transform-center-x="-8.1700521" inkscape:transform-center-y="-7.3507312">
            <tspan id="tspan11-6-8-3-2" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 10
            </tspan>
          </text>
        </g>
        <g id="g63">
          <path
            style="fill:#FFE8D6;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 42.33146,208.39892 21.582706,7.39616 3.250666,-5.28525 -21.964296,-7.60101 z" id="path54" /><text
            xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-3-1-3-9-8-0"
            transform="matrix(0.6183731,0.22440274,-0.20514243,0.56529862,-20.010042,148.47257)"
            inkscape:label="Vialidad">
            <tspan id="tspan11-2-1-2-4-5-4" style="stroke-width:0.264583" x="131.36562" y="55.165623">VIALIDAD</tspan>
          </text><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-5-4-3-7-7"
            transform="matrix(0.79243491,0.27738927,-0.25358116,0.72442084,-39.833484,130.50036)"
            inkscape:label="Vialidad" inkscape:transform-center-x="-8.1700521" inkscape:transform-center-y="-7.3507312">
            <tspan id="tspan11-6-8-3-2-1" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 12
            </tspan>
          </text>
        </g>
        <g id="g62">
          <path
            style="fill:#FFE8D6;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 28.560255,234.02453 2.706939,-5.60096 21.176098,7.43093 -2.952492,5.4548 z" id="path64" /><text
            xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-3-1-3-9-8-0-4"
            transform="matrix(0.6183731,0.22440274,-0.20514243,0.56529862,-34.205807,174.36272)"
            inkscape:label="Vialidad">
            <tspan id="tspan11-2-1-2-4-5-4-4" style="stroke-width:0.264583" x="131.36562" y="55.165623">VIALIDAD</tspan>
          </text><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-5-4-3-7-7-3"
            transform="matrix(0.79243491,0.27738927,-0.25358116,0.72442084,-53.988693,156.29724)"
            inkscape:label="Vialidad" inkscape:transform-center-x="-8.1700521" inkscape:transform-center-y="-7.3507312">
            <tspan id="tspan11-6-8-3-2-1-4" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 14
            </tspan>
          </text>
        </g>
        <g id="g61">
          <path
            style="fill:#FFE8D6;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
            d="m 21.801447,247.66474 -2.860807,5.76296 21.348568,4.95267 2.836002,-5.35781 z" id="path69" /><text
            xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-3-1-3-9-8-0-4-1"
            transform="matrix(0.63726271,0.1632119,-0.14920355,0.58256695,-49.014132,199.89639)"
            inkscape:label="Vialidad">
            <tspan id="tspan11-2-1-2-4-5-4-4-6" style="stroke-width:0.264583" x="131.36562" y="55.165623">VIALIDAD
            </tspan>
          </text><text xml:space="preserve"
            style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
            y="55.165623" id="text11-5-4-3-7-7-3-7"
            transform="matrix(0.81573872,0.19866612,-0.18161475,0.74572451,-70.977058,184.09395)"
            inkscape:label="Vialidad" inkscape:transform-center-x="-7.4127748" inkscape:transform-center-y="-8.1034273">
            <tspan id="tspan11-6-8-3-2-1-4-4" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 16
            </tspan>
          </text>
        </g><text xml:space="preserve"
          style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
          y="55.165623" id="text11-8"
          transform="matrix(0.79267108,0.27671371,-0.25296357,0.72463673,55.21666,-40.078342)"
          inkscape:label="Vialidad">
          <tspan id="tspan11-4" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 1
          </tspan>
        </text><text xml:space="preserve"
          style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
          y="55.165623" id="text11-8-4"
          transform="matrix(0.79267108,0.27671371,-0.25296357,0.72463673,38.563764,-3.4335503)"
          inkscape:label="Vialidad">
          <tspan id="tspan11-4-9" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 3
          </tspan>
        </text><text xml:space="preserve"
          style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
          y="55.165623" id="text11-8-4-3"
          transform="matrix(0.79267108,0.27671371,-0.25296357,0.72463673,24.805436,20.775825)"
          inkscape:label="Vialidad">
          <tspan id="tspan11-4-9-5" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 5
          </tspan>
        </text><text xml:space="preserve"
          style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
          y="55.165623" id="text11-8-4-3-9"
          transform="matrix(0.79267108,0.27671371,-0.25296357,0.72463673,14.751271,42.736241)"
          inkscape:label="Vialidad">
          <tspan id="tspan11-4-9-5-7" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 7
          </tspan>
        </text><text xml:space="preserve"
          style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
          y="55.165623" id="text11-8-4-3-9-3"
          transform="matrix(0.79267108,0.27671371,-0.25296357,0.72463673,-17.263317,92.345616)"
          inkscape:label="Vialidad">
          <tspan id="tspan11-4-9-5-7-4" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 9
          </tspan>
        </text><text xml:space="preserve"
          style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
          y="55.165623" id="text11-8-4-3-9-3-3"
          transform="matrix(0.79267108,0.27671371,-0.25296357,0.72463673,-31.683105,117.8779)"
          inkscape:label="Vialidad">
          <tspan id="tspan11-4-9-5-7-4-6" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 11</tspan>
        </text><text xml:space="preserve"
          style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
          y="55.165623" id="text11-8-4-3-9-3-3-6"
          transform="matrix(0.79267108,0.27671371,-0.25296357,0.72463673,-45.970605,143.54248)"
          inkscape:label="Vialidad">
          <tspan id="tspan11-4-9-5-7-4-6-3" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 13</tspan>
        </text><text xml:space="preserve"
          style="font-size:3.175px;white-space:pre;inline-size:0;fill:#000000;stroke-width:0.264583" x="131.36562"
          y="55.165623" id="text11-8-4-3-9-3-3-6-5"
          transform="matrix(0.79267108,0.27671371,-0.25296357,0.72463673,-58.670605,163.12166)"
          inkscape:label="Vialidad">
          <tspan id="tspan11-4-9-5-7-4-6-3-5" style="stroke-width:0.264583" x="131.36562" y="55.165623">Fracc. 15
          </tspan>
        </text>
        <path
          style="fill:#7a7a7a;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
          d="m 13.592969,252.08177 5.22552,1.32291 12.501563,-25.06927 14.188281,-25.53229 10.31875,-20.24062 8.063698,-16.05359 8.185129,-15.94931 6.080381,-11.45918 5.612659,-10.33665 11.131775,5.33203 10.851145,-20.86038 -11.505954,-5.28526 3.461141,-5.3788 5.706203,-10.196332 5.89329,-10.851141 2.75956,-5.659433 4.72399,-9.635066 8.13836,-15.996081 -21.09425,-7.390003 -2.47892,6.641647 15.43481,4.91108 z"
          id="path116" inkscape:label="Calle" />
        <path
          style="fill:#7a7a7a;fill-opacity:1;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
          d="m 47.076186,261.38625 11.973675,-22.26355 5.77945,-10.48315 7.408334,-12.27005 24.908147,-41.72223 8.465758,-14.21874 6.87551,-11.41241 6.73519,-11.69304 7.32112,-13.32624 2.97657,-5.29166 2.18281,-3.96875 1.85208,-2.64584 5.22552,-6.68073 4.36563,-5.754683 2.44739,-3.505729 2.31511,-3.571875 3.09546,-4.926533 3.41437,-5.26187 2.89987,-4.373197 2.92326,-4.583671 2.8531,-4.326427 3.3676,-4.139337 3.36759,-4.279651 4.86431,-5.893295 4.13933,-5.519116 3.5313,-4.58367 2.82972,-3.905478 2.66617,-3.538744 3.67109,-5.490104 2.74505,-3.96875 2.38125,-3.604948 5.42396,-8.003645 -8.80379,-1.36247 -12.84279,20.624943 -5.85822,7.658942 -15.35296,19.468915 -3.80024,5.051394 -6.67673,10.01509 -7.68817,12.020447 -3.32667,5.261869 -7.78757,10.073553 -4.31473,5.75298 -2.33276,4.09256 -3.12204,5.50158 -11.37149,20.45405 -44.873802,74.97472 -5.729883,9.76478 -8.738498,15.78851 -9.167345,17.04845 z"
          id="path72" inkscape:label="Calle 2" />
        <path
          style="fill:#ffffff;fill-opacity:0;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
          d="M 180.45222,35.377965 193.42629,14.756618" id="path73" />
        <path
          style="fill:#ffffff;fill-opacity:0;stroke:#000000;stroke-width:0.165;stroke-dasharray:none;stroke-opacity:1"
          d="m 13.634088,252.12536 1.613638,-3.11035 2.361996,-4.5369 1.403165,-2.61924 3.811932,-7.34323 2.666013,-5.05139 5.144939,-9.86893 1.683798,-3.18051 2.408766,-4.65383 1.964431,-3.78854 2.455538,-4.72399 2.432153,-4.51352 4.607058,-9.05041 1.520096,-2.78294 2.970032,-5.82314 2.946647,-5.58927 3.99902,-7.57709 2.829716,-5.33203 3.110349,-5.96345 4.747375,-9.16734 6.384401,-12.13738 4.443356,-8.51254 6.477945,-12.55833 3.063576,-5.84651 8.559307,-16.39365 6.711805,-12.838959 12.7688,-24.812635 -15.38403,-4.905401 2.54507,-6.507006 20.86624,7.232147"
          id="path75" sodipodi:nodetypes="cccccccccccccccccccccccccccc" inkscape:label="path75" /><text
          xml:space="preserve" style="font-size:4.53765px;fill:#000000;stroke-width:0.378137" x="36.908596" y="268.5925"
          id="text249">
          <tspan sodipodi:role="line" id="tspan249" style="stroke-width:0.378137" x="36.908596" y="268.5925">EJIDO DE
            TIZIMÍN</tspan>
        </text><text xml:space="preserve" style="font-size:4.53765px;fill:#000000;stroke-width:0.378137" x="156.45479"
          y="13.271128" id="text249-8">
          <tspan sodipodi:role="line" id="tspan249-8" style="stroke-width:0.378137" x="156.45479" y="13.271128">EJIDO DE
            TIZIMÍN</tspan>
        </text><text xml:space="preserve" style="font-size:4.53765px;fill:#000000;stroke-width:0.378137" x="106.36419"
          y="22.221083" id="text249-8-0">
          <tspan sodipodi:role="line" id="tspan249-8-1" style="stroke-width:0.378137" x="106.36419" y="22.221083">
            FRANCISCO CONDE</tspan>
        </text><text xml:space="preserve" style="font-size:4.53765px;fill:#000000;stroke-width:0.378137" x="-66.061371"
          y="124.05862" id="text249-8-0-5" transform="rotate(-62.737757)">
          <tspan sodipodi:role="line" id="tspan249-8-1-1" style="stroke-width:0.378137" x="-66.061371" y="124.05862">
            FRACC. BENITO JUARÉZ</tspan>
        </text><text xml:space="preserve" style="font-size:4.53765px;fill:#000000;stroke-width:0.378137" x="-118.02924"
          y="179.76639" id="text249-8-0-5-4" transform="rotate(-60.1295)">
          <tspan sodipodi:role="line" id="tspan249-8-1-1-5" style="stroke-width:0.378137" x="-118.02924" y="179.76639">
            CARRETERA A CHEN KEKEN</tspan>
        </text><text xml:space="preserve" style="font-size:4.53765px;fill:#000000;stroke-width:0.378137" x="-151.76358"
          y="123.24326" id="text249-8-0-5-6" transform="rotate(-62.737757)">
          <tspan sodipodi:role="line" id="tspan249-8-1-1-0" style="stroke-width:0.378137" x="-151.76358" y="123.24326">
            FRACC. BENITO JUARÉZ</tspan>
        </text><text xml:space="preserve" style="font-size:4.53765px;fill:#000000;stroke-width:0.378137" x="-143.5955"
          y="174.68513" id="text249-8-0-5-6-7" transform="rotate(-57.616824)">
          <tspan sodipodi:role="line" id="tspan249-8-1-1-0-1" style="fill:#ffffff;stroke-width:0.378137" x="-143.5955"
            y="174.68513">CALLE 44</tspan>
        </text><text xml:space="preserve" style="font-size:4.53765px;fill:#000000;stroke-width:0.378137" x="-6.1324363"
          y="172.5128" id="text249-8-0-5-6-7-7" transform="rotate(-57.616824)">
          <tspan sodipodi:role="line" id="tspan249-8-1-1-0-1-6" style="fill:#ffffff;stroke-width:0.378137"
            x="-6.1324363" y="172.5128">CALLE 44</tspan>
        </text>
      </g>
    </svg>
    <InfoModal v-if="showModal" :showModal="showModal" :loteInfo="loteInfo" @close="closeModal" />
  </body>

</template>

<script>
import { useRouter } from "vue-router";
import InfoModal from "@/components/InfoModal.vue";

export default {
  components: {
    InfoModal

  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  data() {
    return {
      showModal: false, // Estado para controlar la visibilidad del modal
      selectedPathId: null, // ID del path seleccionado
      loteInfo: null, // Información del lote
    };
  },
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    handleClick(event) {
      const clickedPathId = event.target.id; // Obtén el ID del path clickeado
      const idNumber = parseInt(clickedPathId); // Convierte el ID a número

      // Verifica si el ID es un número y está entre 1 y 60
      if (!isNaN(idNumber) && idNumber >= 1 && idNumber <= 56) {
        this.selectedPathId = idNumber; // Guarda el ID del path seleccionado
        this.fetchLoteInfo(idNumber); // Llama la función para obtener la información del lote
        this.showModal = true; // Muestra el modal
      }
    },
    fetchLoteInfo(pathId) {
      // Lógica para obtener la información del lote basada en el ID del path
      this.loteInfo = pathId
    },
    closeModal() {
      this.showModal = false; // Oculta el modal
    },
  },
};
</script>

<style scoped>
body {
  background-color: #E3E5D3;
}


th {
  text-align: center;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E3E5D3;
}

table {
  width: 250px;
  background-color: #C5CBB7;
  margin: 0 auto;
  /* Centra la tabla en pantallas pequeñas */
  z-index: 10;
  /* Asegura que la tabla esté por encima de la imagen */
}

.simbologia {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  width: 100%;
}

.symbol {
  width: 20px;
  height: 20px;
  margin: 0.5em 0 0.2em 0.2em;
  display: inline-block;
  border-radius: 4px;
}

.plano {
  width: 100%;
  margin-top: 1em;
}


.no-seleccionable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*estandar*/
}
g[lote] {
    cursor: pointer;
}
.description {
  text-align: center;
  align-items: center;
  background-color: #E3E5D3;
  font-size: large;
}

/* Media query para pantallas mayores a 425px */
@media (min-width: 768px) {
  .simbologia {
    position: absolute;
    top: 4em;
    /* Ajusta esta distancia desde la parte superior de la imagen */
    left: 4em;
    /* Ajusta esta distancia desde la parte izquierda de la imagen */
    justify-content: flex-start;
    /* Alinea la simbología a la izquierda */
  }

  table {
    margin-left: 0;
    /* Quita el margen auto para alinear a la izquierda */
    margin-bottom: 0;
  }

  .plano {
    position: relative;
    z-index: 1;
    /* Asegura que la imagen esté detrás de la tabla */
  }

  .container {
    left: 0;
    /* Ajusta esta distancia desde la parte izquierda de la imagen */
    margin-left: 0;
    padding-left: 0;
  }
}
</style>
